














































































































































import Vue from "vue";
import _ from "lodash";

import Toc from "@/components/widgets/Toc.vue";
import * as tutorialText from "@/resources/docs/tutorial";

const VIEW_TITLE = "ASQ: tutorial";

export default Vue.extend({
  name: "TutorialView",
  components: {
    Toc,
  },
  data() {
    return {
      docs: tutorialText,
      outline: {
        introduction: {
          ref: "introduction",
          label: "Introduction",
          focus: false,
        },
        "text-query": {
          ref: "text-query",
          label: "Extraction of claims",
          focus: false,
        },
        "entity-harmonization": {
          ref: "entity-harmonization",
          label: "Harmonization of the query entities",
          focus: false,
        },
        "evidence-retrieval": {
          ref: "evidence-retrieval",
          label: "Retrieval of evidence",
          focus: false,
        },
        others: {
          ref: "others",
          label: "Other topics",
          focus: false,
        },
      },
    };
  },
  computed: {
    outlineItems(): Array<Record<string, any>> {
      return _.chain(this.outline)
        .mapValues((item) => item)
        .value();
    },
  },
  mounted: function () {
    document.title = VIEW_TITLE;
    this.$store.dispatch("queryStage/setQueryMode", "off");
  },
  methods: {
    onIntersect(entries): void {
      // console.log(entries);
      const focus = entries[0].isIntersecting;
      const id = entries[0].target.id;
      this.outline[id].focus = focus;
    },
    jump(ref): void {
      const target = this.$refs[ref];
      // @ts-ignore
      this.$vuetify.goTo(target);
    },
  },
});
